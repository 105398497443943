import React, { useState, useMemo, useEffect, useRef } from "react"
import TinderCard from "react-tinder-card"
import pointer_hand from "../images/pointer_hand.png"
import $ from "jquery"
import backgroundplus from "../images/deal-swipe-mobile.png"
import axios from "axios"
import {
  AUTH_TOKEN_KEY,
  URL_GETALL_ADVANTAGES,
  translate,
  FR,
  NL,
  getUserLanguage,
  KEY_LANGUAGE_USER,
  KEY_LANGUAGE_BROWSER,
  DOMAIN_API,
  URL_GETALL_VAL,
  URL_TAKE_ADVANTAGE_USER,
  URL_GETALL,
  URL_GETALL_ADVANTAGE_PARTNERS,
  ALERT_MSG_ERROR,
} from "../utils"
import AdvantageModal from "../components/modalAdvantage"
import Layout from "../components/layout"
import ModalPartnerUnselected from "../components/modalPartnerUnselected"
import { Button, Col, Container, Row } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const alreadyRemoved = []

function Test() {
  const [db, setDb] = useState([])
  const [charactersState, setcharactersState] = useState([])
  const [childRefs, setchildRefs] = useState([])
  var mouseclicked=false;
  const [showModalPartnerUnselected, setshowModalPartnerUnselected] = useState(
    false
  )
  const [showAdvantageModal, setshowAdvantageModal] = useState(false)
  const [activeAdvantage, setactiveAdvantage] = useState(undefined)

  const [
    idPartnerForModalPartnerUnselected,
    setidPartnerForModalPartnerUnselected,
  ] = useState(null)
  const [activeView, setactiveView] = useState("gridview")
  var langs = getUserLanguage()

  const showModalUnselected = id => {
    setshowModalPartnerUnselected(true)
    setidPartnerForModalPartnerUnselected(id)
  }
  
  const ele = useRef()
  useEffect(() => {
    getAll()
    $("body").on("mousedown",".swipe",function (e) {
      e.preventDefault();
      mouseclicked=true;
    })
    $("body").on("mousemove",".swipe",function (e) {
      e.preventDefault()
     
      if (mouseclicked) {
        console.log("hey6",e.clientX)
        // const newLocation = dragableTouchmove(mouseCoordinatesFromEvent(ev), element.current, offset, lastLocation)
        // speed = calcSpeed(lastLocation, newLocation)
        // lastLocation = newLocation
      }
    
    })
    ///lang
    if (typeof window !== "undefined") {
      let wind = window.location.search

      let lang = localStorage.getItem(KEY_LANGUAGE_USER)
      console.log("ooo", lang)
      if (lang) {
        langs = lang
      }
    }

    //fin  lang
  }, [mouseclicked])
  const [characters, setCharacters] = useState([])
  const [lastDirection, setLastDirection] = useState()
  const [showResults, setshowResults] = useState(true)

  const swiped = (direction, nameToDelete) => {
    if (direction != "up" && direction != "down") {
      setshowResults(false)

      if (direction == "right") {
        takeAdvantage(nameToDelete)
      }
      setLastDirection(direction)
      alreadyRemoved.push(nameToDelete.id)
    }
  }

  const outOfFrame = id => {
    setcharactersState(charactersState.filter(character => character.id != id))
    setCharacters(charactersState)

    if (alreadyRemoved.length === charactersState.length) {
      window.location.href = "/feed"
    }
  }

  const toggleAdvantageModal = item => {
    setshowAdvantageModal(!showAdvantageModal)
    if (!showAdvantageModal) {
      setactiveAdvantage(item)
    } else {
      setactiveAdvantage(undefined)
    }
  }

  // const swipe = dir => {
  //   const cardsLeft = characters.filter(
  //     person => !alreadyRemoved.includes(person.id)
  //   )
  //   if (cardsLeft.length) {
  //     const toBeRemoved = cardsLeft[cardsLeft.length - 1].id // Find the card object to be removed
  //     const index = db.map(person => person.id).indexOf(toBeRemoved) // Find the index of which to make the reference to
  //     alreadyRemoved.push(toBeRemoved) // Make sure the next card gets removed next time if this card do not have time to exit the screen
  //     childRefs[index].current.swipe(dir) // Swipe the card!
  //   }
  // }
  const takeAdvantage = advantage => {
    if (advantage) {
      const { id } = advantage
      const data = {
        id: id,
      }
      console.log(data)
      const token = localStorage.getItem(AUTH_TOKEN_KEY)
      const header = {
        headers: { Authorization: `Bearer ${token}` },
      }
      axios
        .post(URL_TAKE_ADVANTAGE_USER, data, header)
        .then(res => {
          console.log("Succes")
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const getAll = async () => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    await axios
      .get(URL_GETALL_ADVANTAGE_PARTNERS)
      .then(res => {
        if (res.data) {
          setDb(res.data)
          setcharactersState(res.data)

          setchildRefs(
            Array(res.data.length)
              .fill(0)
              .map(i => React.createRef())
          )

          setCharacters(res.data)
          // window.location.href = "/feed"
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err)
        }
      })
  }

  return (
    <Layout ClassIndex="mobile">
      <ToastContainer />
      <div className="Swp">
        <h1 className="text-center title-mobile pt-2">
          {translate(
            "cette première selection de deals devraient vous intéresser"
          )}
        </h1>

        <Row className="justify-content-center mb-3">
          <Col xs={4} className="text-swip-right text-secondary text-swip">
            <p className="text-danger text-uppercase">
              {" "}
              {translate(`swipe links`)}
            </p>
            {translate(`Ik Heb voorlopig GEEN interesse in deze deal`)}
          </Col>
          <Col xs={3} className="text-center pb-3 div-choice-swipe">
            <img className="mt-2" src={pointer_hand} />
          </Col>
          <Col
            xs={4}
            className="text-swip-left text-secondary text-swip text-right"
          >
            <p className="text-success text-uppercase ">
              {" "}
              {translate("swipe rechts")}
            </p>
            {translate(`Dere deal interesseert mij`)}
          </Col>
        </Row>
        {/* <p className="text-center vp pt-3">
          {translate("Cliquez sur le deal pour en savoir plus.")}
          <br />
          {translate(
            "Le partenaire vous contactera sans engagement pour valider le deal."
          )}
        </p>
        <a href="/feed" className="oppp text-center pb-3">
          {translate("Cliquez dessus pour en savoir +")}
        </a> */}
        <div className="cardContainer">
          {characters.map((character, index) => (
            <TinderCard
              ref={childRefs[index]}
              className="swipe"
              key={`swip-${character.id}`}
              onSwipe={dir => swiped(dir, character)}
              onCardLeftScreen={() => outOfFrame(character.id)}
              preventSwipe={["down", "up"]}
            >
           
              <div
                className="card position-relative"
                style={{
                  backgroundImage:
                    langs == "FR"
                      ? "url(" +
                        DOMAIN_API() +
                        "/vmobile/" +
                        character.imgFR +
                        ")"
                      : "url(" +
                        DOMAIN_API() +
                        "/vmobile/" +
                        character.imgNL +
                        ")",
                }}
              >
                <div className="notificationsweep sides rightyes d-none d-none">
                  {" "}
                  {translate("Oui je veux")}
                </div>
                <div className="notificationsweep sides leftno d-none">
                  {translate("Non merci")}
                </div>
                <div className="position-absolute text-info-adv">
                  <div className="fleshContainer">
                    <div
                      className="fleshplusmobile"
                      style={{ backgroundImage: `url(${backgroundplus})` }}
                    >
                      {character.val ? (
                        <>
                          <span className="text-light span-fm">
                            <span className="text-light label-adv">
                              {translate("votre avantage")}
                            </span>
                            <br />
                            {character.val} <label>{character.symbole}</label>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <p
                  onTouchStart={() => {
                    toggleAdvantageModal(character)
                  }}
                  className="btn-info-adv position-absolute "
                >
                  + {translate("d'infos sur ce deal")}
                </p>
              </div>
            </TinderCard>
          ))}
        </div>
        <div className="buttons Swpb">
          <button
            variant="success"
            onClick={() => (window.location.href = "/feed")}
          >
            {" "}
            {translate("Allez directement vers le site")}
          </button>
        </div>
      </div>
      {showAdvantageModal ? (
        <AdvantageModal
          show={showAdvantageModal}
          toggle={toggleAdvantageModal}
          advantage={activeAdvantage}
        />
      ) : (
        ""
      )}
    </Layout>
  )
}

export default Test
